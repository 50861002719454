::-webkit-scrollbar {
  width: 10px; 
}

::-webkit-scrollbar-track {
  background: #DDD;
}

::-webkit-scrollbar-thumb {
  background: #47B45F;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2E653A;
}


